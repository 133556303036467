import React from "react"
import PatientFormConfirmation from "../components/Form/PatientFormConfirmation"
import Layout from "../helper/layout"

const PatientFormConfirmationPage = () => {
  return (
    <Layout>
      <PatientFormConfirmation />
    </Layout>
  )
}

export default PatientFormConfirmationPage
